<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col justify="center" align="center">
        <h1>Page Not Found</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "404",
};
</script>

<style scoped>
</style>